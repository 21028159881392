import { expandShorthand } from '@/camo/utils/expand-shorthand'
import { type ToastOverrides } from 'baseui/toast'
import { InnerContainer } from './InnerContainer'

const overrides: ToastOverrides = {
  Body: {
    style: ({ $theme }) => {
      return expandShorthand({
        color: $theme.colors.toastText,
        borderRadius: $theme.borders.surfaceBorderRadius,
        padding: 0,
        margin: `${$theme.sizing.scale300} 0`,
        width: '270px',
        minHeight: $theme.sizing.scale1200,
        fontSize: $theme.typography.LabelSmall.fontSize,
        fontWeight: $theme.typography.LabelSmall.fontWeight,
      })
    },
  },
  CloseIcon: {
    props: {
      'data-testid': 'toast-close-icon',
    },
    style: ({ $theme }) =>
      expandShorthand({
        margin: `auto ${$theme.sizing.scale300} auto 0`,
        height: '20px',
        width: '20px',
        transition: `opacity ${$theme.animation.timing100} ${$theme.animation.easeInCurve}`,
        ':hover': {
          opacity: 0.7,
        },
      }),
  },
  InnerContainer: {
    component: InnerContainer,
  },
}

export { overrides }
