import { palette } from './palette'

const basicSwatches = {
  primary: '#1F364D',
  primaryDark: '#192B3E',
  primaryDarker: '#162636',
  primaryDarkest: '#121417',

  secondary: '#00FFBB',
  secondaryDark: '#00E6A8',
  secondaryDarker: '#00CF97',
  secondaryDarkest: '#008461',

  accent: '#0057AF',
  accentDark: '#004E9E',
  accentDarker: '#00468C',
  accentDarkest: '#002245',

  black: '#000000',
  white: '#FFFFFF',

  transparent: '#12141708',
}

const visualSwatches = {
  grayLightest: palette.gray50,
  grayLighter: palette.gray100,
  grayLight: palette.gray200,
  gray: palette.gray400,
  grayDark: palette.gray500,
  grayDarker: palette.gray600,
  grayDarkest: palette.gray800,

  purpleLightest: palette.purple50,
  purpleLighter: palette.purple100,
  purpleLight: palette.purple200,
  purple: palette.purple400,
  purpleDark: palette.purple500,
  purpleDarker: palette.purple600,
  purpleDarkest: palette.purple800,

  blueLightest: palette.blue50,
  blueLighter: palette.blue100,
  blueLight: palette.blue200,
  blue: palette.blue400,
  blueDark: palette.blue500,
  blueDarker: palette.blue600,
  blueDarkest: palette.blue800,

  cyanLightest: palette.cyan50,
  cyanLighter: palette.cyan100,
  cyanLight: palette.cyan200,
  cyan: palette.cyan400,
  cyanDark: palette.cyan500,
  cyanDarker: palette.cyan600,
  cyanDarkest: palette.cyan800,

  turquoiseLightest: palette.turquoise50,
  turquoiseLighter: palette.turquoise100,
  turquoiseLight: palette.turquoise200,
  turquoise: palette.turquoise400,
  turquoiseDark: palette.turquoise500,
  turquoiseDarker: palette.turquoise600,
  turquoiseDarkest: palette.turquoise800,

  crayolaLightest: palette.crayola50,
  crayolaLighter: palette.crayola100,
  crayolaLight: palette.crayola200,
  crayola: palette.crayola400,
  crayolaDark: palette.crayola500,
  crayolaDarker: palette.crayola600,
  crayolaDarkest: palette.crayola800,

  greenLightest: palette.green50,
  greenLighter: palette.green100,
  greenLight: palette.green200,
  green: palette.green400,
  greenDark: palette.green500,
  greenDarker: palette.green600,
  greenDarkest: palette.green800,

  orangeLightest: palette.orange50,
  orangeLighter: palette.orange100,
  orangeLight: palette.orange200,
  orange: palette.orange400,
  orangeDark: palette.orange500,
  orangeDarker: palette.orange600,
  orangeDarkest: palette.orange800,

  pinkLightest: palette.pink50,
  pinkLighter: palette.pink100,
  pinkLight: palette.pink200,
  pink: palette.pink400,
  pinkDark: palette.pink500,
  pinkDarker: palette.pink600,
  pinkDarkest: palette.pink800,

  redLightest: palette.red50,
  redLighter: palette.red100,
  redLight: palette.red200,
  red: palette.red400,
  redDark: palette.red500,
  redDarker: palette.red600,
  redDarkest: palette.red800,
}

const statusSwatches = {
  successLightest: palette.green50,
  successLighter: palette.green100,
  successLight: palette.green200,
  success: palette.green400,
  successDark: palette.green500,
  successDarker: palette.green600,
  successDarkest: palette.green800,

  warningLightest: palette.orange50,
  warningLighter: palette.orange100,
  warningLight: palette.orange200,
  warning: palette.orange400,
  warningDark: palette.orange500,
  warningDarker: palette.orange600,
  warningDarkest: palette.orange800,

  errorLightest: palette.red50,
  errorLighter: palette.red100,
  errorLight: palette.red200,
  error: palette.red400,
  errorDark: palette.red500,
  errorDarker: palette.red600,
  errorDarkest: palette.red800,

  informationLightest: palette.blue50,
  informationLighter: palette.blue100,
  informationLight: palette.blue200,
  information: palette.blue400,
  informationDark: palette.blue500,
  informationDarker: palette.blue600,
  informationDarkest: palette.blue800,
}

export { basicSwatches, visualSwatches, statusSwatches }
