import { TypographyProvider } from '@/camo/atoms/Typography/TypographyProvider'
import { TypographyColor, TypographySize } from '@/camo/atoms/Typography/enums'
import { useTypographyColors } from '@/camo/atoms/Typography/use-typography-colors'
import { Close } from '@/camo/icons/icons/Close'
import { type JustifyContent } from '@/camo/layouts'
import { expandShorthand } from '@/camo/utils/expand-shorthand'
import { useStyletron, type ThemeT } from '@/camo/utils/theme'
import { Notification as BaseNotification } from 'baseui/notification'
import {
  KIND as BaseNotificationKind,
  type ToastProps as BaseNotificationProps,
  type ToastOverrides,
} from 'baseui/toast'
import type { Property } from 'csstype'
import { type PropsWithChildren } from 'react'

enum NotificationKind {
  POSITIVE = 'positive',
  POSITIVE_LIME = 'positive_lime',
  WARNING = 'warning',
  NEGATIVE = 'negative',
  INFO = 'info',
}

const notificationKindMap = {
  [NotificationKind.POSITIVE]: BaseNotificationKind.positive,
  [NotificationKind.WARNING]: BaseNotificationKind.warning,
  [NotificationKind.NEGATIVE]: BaseNotificationKind.negative,
  [NotificationKind.INFO]: BaseNotificationKind.info,
}

interface ExtendedNotificationKindOpts {
  kind: NotificationKind
  fullWidth: boolean
  rounded: boolean
  hideBorder: boolean
  'data-testid': string
  color?: TypographyColor
  backgroundColor?: Property.BackgroundColor
  justifyContent?: JustifyContent
}

const useExtendedNotificationKind = (opts: ExtendedNotificationKindOpts) => {
  const [css] = useStyletron()
  const {
    kind,
    fullWidth,
    rounded,
    hideBorder,
    'data-testid': dataTestId,
    color,
    backgroundColor,
    justifyContent,
  } = opts

  const isBaseKind = Object.keys(BaseNotificationKind).includes(kind)
  const baseKind = isBaseKind
    ? notificationKindMap[kind as keyof typeof BaseNotificationKind]
    : BaseNotificationKind.positive

  const { TypographyColors } = useTypographyColors()

  const defaultFontColor = {
    [NotificationKind.POSITIVE]: TypographyColor.POSITIVE,
    [NotificationKind.POSITIVE_LIME]: TypographyColor.PRIMARY,
    [NotificationKind.NEGATIVE]: TypographyColor.NEGATIVE,
    [NotificationKind.WARNING]: TypographyColor.WARNING,
    [NotificationKind.INFO]: TypographyColor.INFO,
  }

  const typographyColor = color ?? defaultFontColor[kind]

  const overrides: ToastOverrides = {
    Body: {
      style: ({ $theme }) => {
        const theme = $theme as ThemeT
        const background = backgroundColor ?? (!isBaseKind ? theme.colors.secondary : undefined)

        return expandShorthand({
          margin: 0,
          borderRadius: rounded ? $theme.borders.surfaceBorderRadius : 0,
          width: fullWidth ? '100%' : undefined,
          fontSize: $theme.typography.LabelSmall.fontSize,
          fontWeight: $theme.typography.LabelSmall.fontWeight,
          lineHeight: $theme.typography.LabelSmall.lineHeight,
          color: TypographyColors[typographyColor],
          opacity: 1,
          transitionProperty: 'none',
          ...(background ? { backgroundColor: background } : {}),
          ...(justifyContent ? { justifyContent } : {}),
          ...(kind === NotificationKind.NEGATIVE && !hideBorder
            ? { border: `1px solid ${theme.colors.borderNegative}` }
            : {}),
        })
      },
    },
    CloseIcon: {
      component: Close,
      props: {
        'data-testid': dataTestId,
        color: TypographyColors[typographyColor],
        className: css({
          cursor: 'pointer',
        }),
      },
      style: { cursor: 'pointer' },
    },
    InnerContainer: {
      style: () =>
        expandShorthand({
          width: justifyContent !== 'center' ? '100%' : undefined,
        }),
    },
  }

  return {
    overrides,
    baseKind,
    typographyColor,
  }
}

type NotificationProps = Omit<BaseNotificationProps, 'kind'> &
  PropsWithChildren<{
    kind?: NotificationKind
    fullWidth?: boolean
    rounded?: boolean
    hideBorder?: boolean
    color?: TypographyColor
    backgroundColor?: Property.BackgroundColor
    justifyContent?: JustifyContent
    'data-testid'?: string
  }>

const Notification = ({
  fullWidth = false,
  rounded = true,
  kind = NotificationKind.POSITIVE,
  hideBorder = false,
  color,
  backgroundColor,
  children,
  justifyContent,
  'data-testid': dataTestId = 'notification-data-testid',
  ...props
}: NotificationProps) => {
  const { overrides, baseKind, typographyColor } = useExtendedNotificationKind({
    kind,
    fullWidth,
    rounded,
    hideBorder,
    'data-testid': dataTestId,
    color,
    backgroundColor,
    justifyContent,
  })

  return (
    <BaseNotification kind={baseKind} {...props} overrides={overrides}>
      <TypographyProvider childrenProps={{ color: typographyColor, size: TypographySize.SMALL }}>
        {children}
      </TypographyProvider>
    </BaseNotification>
  )
}

export { Notification, NotificationKind, type NotificationProps }
