enum TypographyColor {
  ACCENT = 'accent',
  PRIMARY = 'primary',
  GRAY = 'gray',
  GRAY_DARKER = 'gray_darker',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  WARNING = 'warning',
  INFO = 'info',
  WHITE = 'white',
  BLUE = 'blue',
  DISABLED = 'disabled',
  BLACK = 'black',
  GREEN = 'green',
  GREEN_DARK = 'green_dark',
  GREEN_DARKER = 'green_darker',
  RED = 'red',
  RED_DARK = 'red_dark',
  RED_DARKER = 'red_darker',
  RED_DARKEST = 'red_darkest',
  GRAY_DARK = 'gray_dark',
  ORANGE = 'orange',
  ORANGE_DARKER = 'orange_darker',
  ORANGE_DARKEST = 'orange_darkest',
  PURPLE_DARKER = 'purple_darker',
  PURPLE_DARKEST = 'purple_darkest',
  CRAYOLA_DARKEST = 'crayola_darkest',
  PINK_DARKEST = 'pink_darkest',
  CYAN_DARKEST = 'cyan_darkest',
  TURQUOISE_DARKEST = 'turquoise_darkest',
  BLUE_DARKEST = 'blue_darkest',
  TRANSPARENT = 'transparent',
  GREEN_DARKEST = 'green_darkest',
  INHERIT = 'inherit',
}

enum TypographySize {
  /** WARNING: Not available on all components. */
  XXSMALL = 'XXSmall',
  XSMALL = 'XSmall',
  SMALL = 'Small',
  MEDIUM = 'Medium',
  LARGE = 'Large',
  /** WARNING: Not available on all components. */
  XLARGE = 'XLarge',
  /** WARNING: Not available on all components. */
  XXLARGE = 'XXLarge',
}

enum TypographyWeight {
  NORMAL = 400,
  MEDIUM = 500,
  SEMI_BOLD = 600,
  BOLD = 700,
}

export { TypographyColor, TypographySize, TypographyWeight }
