import { type ButtonKindUnion } from '@/camo/atoms/Button/types'
import { capitalizeString } from '@/camo/utils'
import { type ThemeT } from '@/camo/utils/theme'
import { KIND as BaseButtonKind } from 'baseui/button'
import { type StyleObject } from 'styletron-react'
import { ButtonKind } from './enums'

const buttonKindMap = {
  [ButtonKind.PRIMARY]: BaseButtonKind.primary,
  [ButtonKind.SECONDARY]: BaseButtonKind.secondary,
  [ButtonKind.TERTIARY]: BaseButtonKind.tertiary,
}

const useExtendedButtonKind = (kind: ButtonKindUnion, baseStyle: StyleObject) => {
  const isBaseKind = Object.keys(BaseButtonKind).includes(kind)
  const baseKind = isBaseKind
    ? buttonKindMap[kind as keyof typeof BaseButtonKind]
    : kind === ButtonKind.MINIMAL
      ? 'tertiary'
      : 'primary'

  const style = ({ $theme }: { $theme: ThemeT }) => {
    const extendedKind = capitalizeString(kind) as 'Positive' | 'Negative' | 'Minimal'

    const extendedKindStyle = {
      backgroundColor: $theme.colors[`button${extendedKind}Fill`],
      ':active': {
        backgroundColor: $theme.colors[`button${extendedKind}Active`],
      },
      ':hover': {
        backgroundColor: $theme.colors[`button${extendedKind}Hover`],
      },
    }

    return {
      ...baseStyle,
      ':disabled': {
        ':hover': {
          backgroundColor: extendedKind === 'Minimal' ? 'unset' : $theme.colors.buttonDisabledFill,
        },
        color: $theme.colors.buttonDisabledText,
        backgroundColor: extendedKind === 'Minimal' ? 'unset' : $theme.colors.buttonDisabledFill,
      },
      ...(!isBaseKind && extendedKindStyle),
    }
  }

  return {
    overrides: {
      BaseButton: { style },
    },
    baseKind,
  }
}

export { useExtendedButtonKind }
