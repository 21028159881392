enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
}

enum ButtonKind {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  MINIMAL = 'minimal',
  NEGATIVE = 'negative',
  POSITIVE = 'positive',
}

export { ButtonKind, ButtonSize }
