const palette = {
  purple50: '#F3F1F6',
  purple100: '#C9C4D8',
  purple200: '#A79DBF',
  purple300: '#8676AB',
  purple400: '#6D5C94',
  purple500: '#5D4889',
  purple600: '#52407F',
  purple700: '#4A3772',
  purple800: '#3A2457',

  blue50: '#E3F2FF',
  blue100: '#BCDEFF',
  blue200: '#91CAFF',
  blue300: '#67B5FD',
  blue400: '#45A4FF',
  blue500: '#2A96FE',
  blue600: '#2A87EF',
  blue700: '#2874DB',
  blue800: '#2146AA',

  cyan50: '#EAFAFC',
  cyan100: '#B3EDF5',
  cyan200: '#82E2EF',
  cyan300: '#50D6E8',
  cyan400: '#26CDE2',
  cyan500: '#00C3DD',
  cyan600: '#00B3CA',
  cyan700: '#009DAF',
  cyan800: '#00666B',

  turquoise50: '#E2FAF9',
  turquoise100: '#AEEBE4',
  turquoise200: '#73DFD3',
  turquoise300: '#2AD0BF',
  turquoise400: '#00C5B0',
  turquoise500: '#00B89F',
  turquoise600: '#00A991',
  turquoise700: '#00997D',
  turquoise800: '#007B63',

  crayola50: '#EAFCF3',
  crayola100: '#B5F5D6',
  crayola200: '#7EEEBB',
  crayola300: '#30E6A1',
  crayola400: '#00D575',
  crayola500: '#00C469',
  crayola600: '#00B15B',
  crayola700: '#009736',
  crayola800: '#007C38',

  green50: '#E7F9E9',
  green100: '#C5EFCA',
  green200: '#9EE6A7',
  green300: '#76DC85',
  green400: '#00CA44',
  green500: '#00BA3D',
  green600: '#00AB35',
  green700: '#00961F',
  green800: '#007D15',

  orange50: '#FEF2E0',
  orange100: '#FCDEB1',
  orange200: '#F9C87F',
  orange300: '#F7B24C',
  orange400: '#F59025',
  orange500: '#EE8122',
  orange600: '#E8731F',
  orange700: '#DD5B1C',
  orange800: '#AD4220',

  pink50: '#FFF4F6',
  pink100: '#FFE8ED',
  pink200: '#FFC4D2',
  pink300: '#FF9FB4',
  pink400: '#F48996',
  pink500: '#E8596E',
  pink600: '#F0244D',
  pink700: '#E01E4B',
  pink800: '#BB1446',

  red50: '#FDF1F0',
  red100: '#FFCABC',
  red200: '#FFA791',
  red300: '#FF8466',
  red400: '#F4301B',
  red500: '#E62716',
  red600: '#D41417',
  red700: '#C60005',
  red800: '#A91C12',

  gray50: '#F6F9FC',
  gray100: '#E2EAF1',
  gray200: '#D5DFE8',
  gray300: '#CCD5DF',
  gray400: '#B4C2CE',
  gray500: '#8796A4',
  gray600: '#5B6D7A',
  gray700: '#495862',
  gray800: '#121417',
}

export { palette }
