import { camelToSnakeCase } from 'src/utilities/camel-to-snake-case'
import { toApiDateString } from 'src/utilities/date'
import { setLocale } from 'yup'

const prefixKey = (key: string) => `validation:${key}`

setLocale({
  mixed: {
    required: (params) => {
      const { path } = params
      return { key: prefixKey('required'), values: { field: camelToSnakeCase(path) } }
    },
    // This type of validation error is triggered by yup if an invalid type is provided
    notType: (params) => {
      const { path, type } = params
      if (type === 'number') {
        return { key: prefixKey(`not_type.number`), values: { field: camelToSnakeCase(path) } }
      }
      return { key: prefixKey(`not_type.${camelToSnakeCase(path)}`) }
    },
  },
  array: {
    length: (params) => {
      const { path } = params
      return { key: prefixKey(`length.${camelToSnakeCase(path)}`) }
    },
    min: (params) => {
      const { path, min } = params
      return { key: prefixKey(`array_min.${camelToSnakeCase(path)}`), values: { count: min } }
    },
  },
  number: {
    min: ({ path, min }) => {
      return { key: prefixKey(`min`), values: { field: camelToSnakeCase(path), min } }
    },
    max: ({ path, max }) => {
      return { key: prefixKey(`max`), values: { field: camelToSnakeCase(path), max } }
    },
    positive: ({ path }) => {
      return { key: prefixKey(`positive`), values: { field: camelToSnakeCase(path) } }
    },
    integer: (params) => {
      const { path } = params
      return { key: prefixKey('integer'), values: { field: camelToSnakeCase(path) } }
    },
  },
  date: {
    min: ({ path, min }) => {
      return { key: prefixKey('min'), values: { field: camelToSnakeCase(path), min: toApiDateString(min) } }
    },
    max: ({ path, max }) => {
      return { key: prefixKey('max'), values: { field: camelToSnakeCase(path), max: toApiDateString(max) } }
    },
  },
  string: {
    email: prefixKey('email'),
    url: prefixKey('url'),
    min: ({ path: field, min }: { path: string; min: number }) => ({
      key: prefixKey('string.min'),
      values: { field: camelToSnakeCase(field), min },
    }),
  },
})
