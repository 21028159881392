import online_conversions_batch from 'src/i18n/en/namespaces/online-conversions/online-conversions-batch.json'
import twoFactorAuth from './namespaces/2fa.json'
import * as admin from './namespaces/admin'
import billing from './namespaces/billing.json'
import capTable from './namespaces/cap-table.json'
import certificates from './namespaces/certificates.json'
import common from './namespaces/common.json'
import companiesHouse from './namespaces/companies-house.json'
import companies from './namespaces/companies.json'
import {
  addMissingValuationsWizard,
  adjustments,
  allotments,
  bulkDocumentUpload,
  bulkUpdateStakeholders,
  cancellations,
  changeSecurityWizard,
  company,
  companyAccount,
  banners as companyBanners,
  companyBilling,
  companyForm,
  home as companyHome,
  companyImports,
  relations as companyRelations,
  setup as companySetup,
  companyUpdates,
  compliance,
  conversions,
  debt,
  directors,
  drafts,
  emiCsopLimits,
  equityAwards,
  fundraising,
  importSecurities,
  incentivePool,
  internalDocuments,
  issuance,
  markGrantsAlreadyFiled,
  members,
  peopleRegisterEntries,
  pscs,
  reports as reportsPage,
  roundModelling,
  sbpExpenseReports,
  securities,
  settings,
  settingsAndAddons,
  shareRegisterEntries,
  shares,
  shareRegistersSetup,
  stakeholderHoldings,
  transfers,
  valuations,
  vesting,
} from './namespaces/company'
import companyRegisters from './namespaces/company-registers.json'
import compensation from './namespaces/compensation.json'
import components from './namespaces/components'
import configureSignatories from './namespaces/configure-signatories.json'
import dashboard from './namespaces/dashboard.json'
import disqualifyingEvents from './namespaces/disqualifying-events.json'
import documentSets from './namespaces/document-sets.json'
import documents from './namespaces/documents.json'
import employees from './namespaces/employees.json'
import enums from './namespaces/enums.json'
import errors from './namespaces/errors.json'
import exercise from './namespaces/exercise.json'
import favourite from './namespaces/favourite.json'
import filings from './namespaces/filings.json'
import fundAdmin from './namespaces/fund-admin.json'
import fundingRounds from './namespaces/funding-rounds.json'
import grant from './namespaces/grant.json'
import holdingsDashboard from './namespaces/holdings-dashboard.json'
import * as liquidityEvent from './namespaces/liquidity-event'
import login from './namespaces/login.json'
import oauth from './namespaces/oauth.json'
import reports from './namespaces/reports.json'
import resetPassword from './namespaces/reset-password.json'
import secretaries from './namespaces/secretaries.json'
import securityRestrictions from './namespaces/security-restrictions.json'
import shareAdjustments from './namespaces/share-adjustments.json'
import shareClasses from './namespaces/share-classes.json'
import signUp from './namespaces/sign-up.json'
import stakeholder from './namespaces/stakeholder.json'
import tasks from './namespaces/tasks.json'
import transactions from './namespaces/transactions.json'
import unsupported from './namespaces/unsupported.json'
import upsell from './namespaces/upsell.json'
import {
  bankAccounts,
  capdeskAccount,
  claimCompany,
  identity,
  profile,
  user,
  banners as userBanners,
  home as userHome,
  preferences as userPreferences,
  verifyWizard,
} from './namespaces/user'
import validation from './namespaces/validation.json'
import wizard from './namespaces/wizard.json'

const resources = {
  addMissingValuationsWizard,
  validation,
  login,
  common,
  user,
  documents,
  documentSets,
  companies,
  debt,
  twoFactorAuth,
  signUp,
  profile,
  identity,
  userHome,
  userPreferences,
  userBanners,
  claimCompany,
  bankAccounts,
  capdeskAccount,
  wizard,
  reports,
  company,
  companyBanners,
  companySetup,
  securities,
  settings,
  dashboard,
  online_conversions_batch,
  billing,
  companyBilling,
  companyRelations,
  verifyWizard,
  compensation,
  stakeholder,
  employees,
  tasks,
  transactions,
  certificates,
  companyForm,
  companyHome,
  compliance,
  sbpExpenseReports,
  equityAwards,
  fundraising,
  exercise,
  shares,
  shareClasses,
  fundingRounds,
  bulkUpdateStakeholders,
  unsupported,
  upsell,
  valuations,
  vesting,
  incentivePool,
  issuance,
  companyImports,
  companyUpdates,
  errors,
  holdingsDashboard,
  resetPassword,
  securityRestrictions,
  grant,
  ...liquidityEvent,
  ...admin,
  importSecurities,
  changeSecurityWizard,
  filings,
  secretaries,
  stakeholderHoldings,
  capTable,
  configureSignatories,
  emiCsopLimits,
  allotments,
  oauth,
  bulkDocumentUpload,
  transfers,
  fundAdmin,
  companyRegisters,
  internalDocuments,
  shareAdjustments,
  shareRegisterEntries,
  markGrantsAlreadyFiled,
  members,
  cancellations,
  conversions,
  adjustments,
  directors,
  drafts,
  pscs,
  peopleRegisterEntries,
  enums,
  companiesHouse,
  roundModelling,
  settingsAndAddons,
  ...components,
  companyAccount,
  reportsPage,
  shareRegistersSetup,
  disqualifyingEvents,
  favourite,
}
const namespaces = Object.keys(resources)

export default resources
export { namespaces }
