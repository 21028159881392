import { expandShorthand } from '@/camo/utils/expand-shorthand'
import { useStyletron } from '@/camo/utils/theme'
import { type BlockProps } from 'baseui/block'
import { type Font } from 'baseui/themes'
import type React from 'react'
import { type PropsWithChildren } from 'react'
import { TypographyProvider } from './TypographyProvider'
import { TypographySize, TypographyWeight } from './enums'
import { type StandardTypographySize, type TypographyProps } from './types'
import { useContainerTypography } from './use-container-typography'
import { useTypographyColors } from './use-typography-colors'

/**
 * Creates a typography component wrapping baseweb components
 *
 * This generator consumes a list of size -> component maps and any additional
 * props that should be passed through. It includes the default base web
 * functionality along with extra functionality around text alignment, sizing,
 * etc.
 */
function createTypographyComponent<
  TSize extends StandardTypographySize | TypographySize,
  TProps extends TypographyProps<TSize> = TypographyProps<TSize>,
>(
  baseComponents: { [k in TSize]: React.FC<BlockProps> },
  {
    weight: defaultWeight,
    type,
    ...props
  }: BlockProps & { weight?: TypographyWeight; type: 'Heading' | 'Paragraph' | 'Label' }
) {
  const Component: React.FC<PropsWithChildren<TProps>> = ({
    size: typographySize,
    color: typographyColor,
    align: textAlign,
    uppercase = false,
    weight,
    children,
    ...rest
  }) => {
    const [, theme] = useStyletron()
    const { TypographyColors } = useTypographyColors()
    const parentTypography = useContainerTypography()

    const size = typographySize ?? parentTypography.size ?? TypographySize.MEDIUM
    const fontWeight =
      weight ??
      defaultWeight ??
      (theme.typography[`${type}${size}` as keyof typeof theme.typography] as Font)?.fontWeight ??
      TypographyWeight.MEDIUM
    const BaseComponent: React.FC<BlockProps> =
      baseComponents[size as TSize] || baseComponents[TypographySize.MEDIUM as TSize]

    const overrides = {
      Block: {
        // Baseweb does not expose textAlign by default so we must style it here.
        style: {
          textAlign,
          fontWeight,
          textTransform: uppercase ? 'uppercase' : 'none',
          paddingBottom: 0,
          ...expandShorthand({ margin: 0 }),
        },
      },
    }

    const color = typographyColor ?? parentTypography.color

    if (color) {
      overrides.Block.style.color = TypographyColors[color]
    }

    return (
      <BaseComponent overrides={overrides} {...rest} {...parentTypography} {...props}>
        <TypographyProvider childrenProps={{ size, color, as: 'span' }}>{children}</TypographyProvider>
      </BaseComponent>
    )
  }
  return Component
}

export { createTypographyComponent }
