import { Button, ButtonKind, Divider, Modal, ModalBody, ModalFooter, ModalHeader, type ModalProps } from '@capdesk/camo'
import type React from 'react'
import { useTranslation } from 'react-i18next'

type ErrorModalProps = ModalProps & {
  isOpen?: boolean
  title?: string
  body?: string | React.ReactNode
  handleClose?: () => void
}

const reloadPage = () => {
  window.location.reload()
}

const ErrorModal = ({ title, body, handleClose = reloadPage, ...props }: ErrorModalProps) => {
  const { t } = useTranslation('common')
  return (
    <Modal onClose={handleClose} closeable={false} {...props}>
      <ModalHeader>{title ?? t('error.title')}</ModalHeader>
      <ModalBody>{body ?? t('error.message')}</ModalBody>
      <Divider />
      <ModalFooter>
        <Button kind={ButtonKind.SECONDARY} onClick={handleClose}>
          {t('okay')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export { ErrorModal }
export type { ErrorModalProps }
