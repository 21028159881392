enum Breakpoint {
  XS = 'xsmall',
  S = 'small',
  M = 'medium',
  L = 'large',
  XL = 'xlarge',
}

const breakpoints: { [key in Breakpoint]: number } = {
  [Breakpoint.XS]: 480,
  [Breakpoint.S]: 768,
  [Breakpoint.M]: 1280,
  [Breakpoint.L]: 1600,
  [Breakpoint.XL]: 1600,
}

export { breakpoints, Breakpoint }
