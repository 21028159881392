import { type TerminologySettings } from 'src/i18n/terminology/TerminologyProvider'
import en from 'src/i18n/terminology/languages/en.json'
import { entries } from 'src/utilities/entries'

/**
 *
 * Allows us to support tokens with regex reserved special characters in the terminology
 * replacement rules files. e.g. [/my_cool_token]
 *
 * @param str to escape
 * @returns a string escaped for use in a regex
 */
const escapeForRegExp = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

type MatchSet = Index<string>

type TerminologyValues<T extends keyof TerminologySettings> = TerminologySettings[T]

type LanguageSet<T> = {
  [TTermId in keyof TerminologySettings]: { [TTermValue in TerminologyValues<TTermId>]?: T }
}

/**
 create object structure used to scan for matches e.g.
 {
  "phantom_share_naming": {
    "phantom_share": "virtual share|Virtual Share| ...",
    "virtual_share": "phantom share|Phantom Share| ..."
  },
  "exercise_price_naming": {
    "exercise_price": "strike price|Strike Price| ...",
    "strike_price": "exercise price|Exercise Price| ..."
  }
}
 */
const joinLanguageSet = (languageSet: Index<Index<MatchSet>>) => {
  return entries(languageSet).reduce((joinedLanguageSet: Index<MatchSet>, [termId, termValues]) => {
    joinedLanguageSet[termId] = entries(termValues).reduce<Index<string>>((joinedSetting, [termValue, matchSet]) => {
      joinedSetting[termValue] = Object.keys(matchSet).map(escapeForRegExp).join('|')
      return joinedSetting
    }, {})
    return joinedLanguageSet
  }, {}) as unknown as LanguageSet<string>
}

interface LanguageSets<T> {
  en: LanguageSet<T>
}

const languageSets: Index<Index<Index<Index<string>>>> = { en }
const languageSetsJoin: Index<Index<Index<string>>> = { en: joinLanguageSet(en) }

export { languageSets, languageSetsJoin }
export type { MatchSet, LanguageSets, LanguageSet }
