import { TypographyProvider, TypographySize, TypographyWeight } from '@/camo/atoms'
import { useCanBeUnmounted } from '@/camo/hooks'
import { Close, IconColor } from '@/camo/icons'
import { Block } from '@/camo/layouts'
import { InnerModalProvider } from '@/camo/molecules/Modal/InnerModalProvider'
import { MODAL_BODY_MAX_HEIGHT } from '@/camo/molecules/Modal/consts'
import { expandShorthand } from '@/camo/utils/expand-shorthand'
import { useStyletron, withStyle } from '@/camo/utils/theme'
import { CLOSE_SOURCE } from 'baseui/drawer'
import { Layer } from 'baseui/layer'
import {
  Modal as BaseModal,
  ModalBody as BaseModalBody,
  ModalFooter as BaseModalFooter,
  ModalHeader as BaseModalHeader,
  ROLE,
  SIZE,
  StyledClose,
  StyledDialog,
  type ModalProps as BaseModalProps,
  type ModalOverrides,
} from 'baseui/modal'
import type React from 'react'
import { useCallback, useState, type PropsWithChildren, type ReactNode, type RefCallback } from 'react'
import { createPortal } from 'react-dom'
import { useUnmount } from 'react-use'

type PickedProps = 'children' | 'closeable' | 'isOpen' | 'onClose' | 'autoFocus'
type ModalProps = Pick<BaseModalProps, PickedProps> & {
  id?: string
  width?: Pixels
  closeOnBackdropClick?: boolean
  onAfterClose?: () => void
}

enum ModalWidth {
  DEFAULT = '600px',
  WIDE = '800px',
}

const ModalHeader: React.FC<PropsWithChildren<{ size?: TypographySize.SMALL | TypographySize.XSMALL }>> = withStyle(
  BaseModalHeader,
  ({ $theme, size = TypographySize.SMALL }) =>
    expandShorthand({
      margin: 0,
      position: 'relative',
      ...(size === TypographySize.SMALL ? $theme.typography.HeadingSmall : $theme.typography.HeadingXSmall),
      fontWeight: TypographyWeight.BOLD,
      color: $theme.colors.primary,
      padding: $theme.sizing.scale600,
      borderBottom: `1px solid ${$theme.colors.grayLight}`,
    })
)

const ModalBody: React.FC<PropsWithChildren<{ noPadding?: boolean }>> = ({ noPadding, children, ...props }) => {
  const [, theme] = useStyletron()
  return (
    <BaseModalBody
      $style={expandShorthand({
        color: theme.colors.primary,
        margin: 0,
        padding: noPadding ? 0 : theme.sizing.scale600,
        maxHeight: MODAL_BODY_MAX_HEIGHT,
        overflowY: 'auto',
      })}
      {...props}
    >
      <TypographyProvider childrenProps={{ size: TypographySize.SMALL }}>{children}</TypographyProvider>
    </BaseModalBody>
  )
}

const ModalFooter: React.FC<PropsWithChildren> = withStyle(BaseModalFooter, ({ $theme }) =>
  expandShorthand({
    display: 'flex',
    justifyContent: 'flex-end',
    margin: $theme.sizing.scale600,
    padding: 0,
  })
)
type OverridenDialogProps = Parameters<typeof StyledDialog>[0] & { onAfterClose?: () => void }
const OverridenDialog = ({ onAfterClose, ...props }: OverridenDialogProps) => {
  useUnmount(() => {
    if (!props.$isOpen && onAfterClose) {
      onAfterClose()
    }
  })
  return <StyledDialog {...props} />
}

const Modal: React.FC<ModalProps> = ({
  children,
  width = ModalWidth.DEFAULT,
  onClose,
  closeOnBackdropClick = false,
  onAfterClose,
  ...props
}) => {
  const overrides: ModalOverrides = {
    Dialog: {
      props: {
        onAfterClose,
      },
      component: OverridenDialog,
      style: ({ $theme }) =>
        expandShorthand({
          borderWidth: $theme.borders.border100.borderWidth,
          borderRadius: $theme.borders.radius200,
          width,
          minWidth: '304px',
        }),
    },
    Close: {
      component: (props) => (
        <StyledClose {...props}>
          <Close size="24px" color={IconColor.PRIMARY} />
        </StyledClose>
      ),
      style: ({ $theme }) => ({
        transform: 'translateY(50%)',
        color: $theme.colors.primary,
        top: $theme.sizing.scale100,
      }),
    },
  }

  const [buttonsPortalNode, setButtonsPortalNode] = useState<HTMLDivElement | null>(null)
  const createButtonsPortal = useCallback(
    (buttons: ReactNode) => {
      if (!buttonsPortalNode) {
        return null
      }

      return createPortal(buttons, buttonsPortalNode) as ReactNode
    },
    [buttonsPortalNode]
  )

  const refCallback: RefCallback<HTMLDivElement> = useCallback((node) => {
    const observer = new MutationObserver(() => {})

    if (node) {
      const config = { attributes: true, childList: true, subtree: true }
      observer.observe(node, config)
    }
    setButtonsPortalNode(node)
  }, [])

  const { canBeUnmounted } = useCanBeUnmounted({ isOpen: Boolean(props.isOpen) })

  if (canBeUnmounted) {
    return null
  }

  return (
    <Layer>
      <InnerModalProvider
        value={{
          isInContext: true,
          createButtonsPortal,
        }}
      >
        <BaseModal
          closeable
          animate
          autoFocus
          {...props}
          size={SIZE.default}
          role={ROLE.dialog}
          overrides={overrides}
          onClose={({ closeSource }) => {
            if (closeSource === CLOSE_SOURCE.backdrop && !closeOnBackdropClick) {
              return
            }
            onClose?.({ closeSource })
          }}
        >
          <>
            {children}
            <Block ref={refCallback} />
          </>
        </BaseModal>
      </InnerModalProvider>
    </Layer>
  )
}

export { Modal, ModalBody, ModalFooter, ModalHeader, ModalWidth, type ModalProps }
