import { TypographyColor } from '@/camo/atoms/Typography/enums'
import { useStyletron } from '@/camo/utils/theme'

const useTypographyColors = () => {
  const [, theme] = useStyletron()
  const TypographyColors = {
    [TypographyColor.ACCENT]: theme.colors.accent,
    [TypographyColor.BLUE]: theme.colors.blue,
    [TypographyColor.PRIMARY]: theme.colors.primary,
    [TypographyColor.GRAY]: theme.colors.gray,
    [TypographyColor.GRAY_DARK]: theme.colors.grayDark,
    [TypographyColor.GRAY_DARKER]: theme.colors.grayDarker,
    [TypographyColor.DISABLED]: theme.colors.disabled,
    [TypographyColor.POSITIVE]: theme.colors.notificationPositiveText,
    [TypographyColor.NEGATIVE]: theme.colors.notificationNegativeText,
    [TypographyColor.WARNING]: theme.colors.notificationWarningText,
    [TypographyColor.INFO]: theme.colors.notificationInfoText,
    [TypographyColor.WHITE]: theme.colors.white,
    [TypographyColor.BLACK]: theme.colors.black,
    [TypographyColor.GREEN]: theme.colors.green,
    [TypographyColor.GREEN_DARK]: theme.colors.greenDark,
    [TypographyColor.GREEN_DARKER]: theme.colors.greenDarker,
    [TypographyColor.GREEN_DARKEST]: theme.colors.greenDarkest,
    [TypographyColor.RED]: theme.colors.red,
    [TypographyColor.RED_DARK]: theme.colors.redDark,
    [TypographyColor.RED_DARKER]: theme.colors.redDarker,
    [TypographyColor.RED_DARKEST]: theme.colors.redDarkest,
    [TypographyColor.ORANGE]: theme.colors.orange,
    [TypographyColor.ORANGE_DARKER]: theme.colors.orangeDarker,
    [TypographyColor.ORANGE_DARKEST]: theme.colors.orangeDarkest,
    [TypographyColor.PURPLE_DARKER]: theme.colors.purpleDarker,
    [TypographyColor.PURPLE_DARKEST]: theme.colors.purpleDarkest,
    [TypographyColor.CRAYOLA_DARKEST]: theme.colors.crayolaDarkest,
    [TypographyColor.PINK_DARKEST]: theme.colors.pinkDarkest,
    [TypographyColor.CYAN_DARKEST]: theme.colors.cyanDarkest,
    [TypographyColor.TURQUOISE_DARKEST]: theme.colors.turquoiseDarkest,
    [TypographyColor.BLUE_DARKEST]: theme.colors.blueDarkest,
    [TypographyColor.TRANSPARENT]: 'transparent',
    [TypographyColor.INHERIT]: 'inherit',
  }

  return { TypographyColors }
}

export { useTypographyColors }
